@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,500;1,600&display=swap'); 
@import url('https://fonts.googleapis.com/icon?family=Material+Icons'); 

@import "app/theme/styles/spinner";
@import "~@angular/material/prebuilt-themes/indigo-pink.css"; 
@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base"; 
@import "app/theme/styles/admin";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl"; 
