	/*
  	Flaticon icon font: Flaticon
  	Creation date: 26/10/2020 07:34
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon; 
	font-style: normal; 
}

.flaticon-tray:before { content: "\f100"; }
.flaticon-spaguetti:before { content: "\f101"; }
.flaticon-serving-dish:before { content: "\f102"; }
.flaticon-cheers:before { content: "\f103"; }
.flaticon-champagne-glass:before { content: "\f104"; }
.flaticon-dinner-table:before { content: "\f105"; }
.flaticon-lunch:before { content: "\f106"; }
.flaticon-fast-food:before { content: "\f107"; }
.flaticon-coffee-cup:before { content: "\f108"; }
.flaticon-ice-cream:before { content: "\f109"; }